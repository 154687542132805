
@import "_colors.css";
@import "_typography.css";


body {
  background-color: rgba(253,251,246, 0.5);
  background-image: url('./assets/graphy.v2.png');
}

/* */
.content-wrapper {
  max-width: 80rem;
  display: block;
  margin: auto;
}

.content-wrapper__flex {
  display: flex;
}

.content-wrapper__main-wrapper {
  background: var(--white);
  padding: calc(var(--line-height-em)*2) var(--line-height-em) calc(var(--line-height-em)*3) calc(var(--line-height-em)*2);
  width: 100%;
}

/* menu */

.menu__links a.is-active {
  font-weight: var(--fw-bold);
}


.choice mjx-container[jax="SVG"][display="true"] {
  text-align: left !important;
}
