

.greeting {
  background-repeat: no-repeat;
  background-image: url("https://res.cloudinary.com/crosslinks/image/upload/crosslinks_logo_no-text.png");
}


.viz-wrapper {
  width: 100%;
  height: 600px;
}
