

html, :root {
  --white: #ffffff;

  --almost-black: #333f48;
  --muted: #535f68;
  --extra-muted: #838f98;

  --mit-red: #A31F34;
  --mit-red--dimmed: #930F24;

  --mit-gray: #8A8B8C;
  --mit-light-gray: #8A8B8C;

  --block-gray: #fafafa;

  --brown: #AD5721;
}

body {
  background-color: var(--background-white);
  color: var(--almost-black);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--almost-black);
}

p, ul, ol, pre, table, b, i, strong, em, small, sub, sup {
  color: var(--almost-black);
}

.muted {
  color: var(--muted);
}

.extra-muted {
  color: var(--extra-muted);
}
