

html, :root {
  --fw-light: 100;
  --fw-book: 300;
  --fw-normal: 400;
  --fw-medium: 500;
  --fw-bold: 600;

  --fs-small: .875em;
  --fs-x-small: .8em;

  /* --line-height: 1.625;
  --line-height-em: 1.625em; */

  --line-height: 1.7;
  --line-height-em: 1.7em;
}


html, .root {
  font-size: 15px;
  line-height: var(--line-height);
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0px;
  font-weight: var(--fw-bold);
}

body, p, ul, ol, pre, table, a, b, i, strong, em, small, sub, sup {
  font-family: "Work Sans", sans-serif;
  letter-spacing: 0px;
}

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  text-decoration: none;
  font-family: "GT Sectra Bold", sans-serif;
  letter-spacing: 0px;
}


@media screen and (max-width:639px) {
  html {
    font-size: 14px;
  }
}

body, .article {
  font-size: 1em;
  line-height: var(--line-height);
  margin: auto;
}

h1, .h1 {
  font-size: 2.375em;
  /* line-height: 1.36842105em; */
  /* margin-top: 0.68421053em;
  margin-bottom: 1.36842106em; */

  line-height: calc( var(--line-height)*2 / 2.375 );
  margin-top: calc( var(--line-height-em) / 2.375 );
  margin-bottom: calc( var(--line-height-em) / 2.375 );
}

h2, .h2 {
  font-size: 1.75em;
  line-height: calc( var(--line-height)*2 / 1.75 );
  /* line-height: 1.85714286em; */
  /* margin-top: 0.92857143em;
  margin-bottom: calc(0.92857143em); */

  margin-top: calc( var(--line-height-em) / 1.75 );
  margin-bottom: calc( var(--line-height-em) / 1.75 );

}

h3, .h3 {
  /* line-height: 1.23809524em; */
  /* margin-top: 1.23809524em;
  margin-bottom: 1.23809524em; */
  font-size: 1.3125em;
  line-height: calc( var(--line-height) / 1.3125 );

  margin-top: calc( var(--line-height-em) / 1.3125 );
  margin-bottom: calc( var(--line-height-em) / 1.3125 );
}

h4, .h4 {
  font-size: 1em;
  line-height: var(--line-height);
  margin-top: calc(var(--line-height-em)/2);
  margin-bottom: calc(var(--line-height-em)/2);
}

h5, .h5, h6, .h6 {
  font-size: 1em;
  line-height: var(--line-height);
  margin-top: calc(var(--line-height-em)/2);
  margin-bottom: calc(var(--line-height-em)/2);
}

p, li, pre, table, blockquote {
  line-height: var(--line-height);
  margin-top: 0em;
  margin-bottom: var(--line-height-em);
}

li {
  margin-bottom: calc(var(--line-height-em)/2);
}

ul ul, ol ol, ul ol, ol ul {
  line-height: var(--line-height);
  margin-top: 0em;
  margin-bottom: 0em;
}

.large {
  font-size: 1.3125em;
  line-height: var(--line-height);
  margin-bottom: calc( var(--line-height-em) / 1.3125 );

  /* margin-bottom: 1.23809524em; */
}

small, .small {
  margin-top: 0;
  font-size: .875em;
  line-height: var(--line-height);
  margin-bottom: var(--line-height-em);
}

.x-small {
  font-size: .8em;
  line-height: var(--line-height);
  margin-bottom: var(--line-height-em);
}

.medium {
  font-weight: var(--fw-medium);
}

b, .bold {
  font-weight: var(--fw-bold);
}

/* ---- margins --- */

.bottom-margin {
  margin-bottom: 1.625em;
}

/* Let's make sure all's aligned */

hr, .hr {
  border: 1px solid;
  margin: -1px 0;
}

sub, sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}


a {
  color: var(--mit-red);
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: var(--brown);
}

/* -----  */
.text-center {
  text-align: center;
}
