
.root {
  max-width: 100%;
  width: 100%;
  height: 100%;
}

.canvas {
  max-width: 100%;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 3px 1px rgba(30, 30, 30, .3);
}
