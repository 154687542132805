

.search__dropdown {
  line-height: 1;
}

.topic {
  /* line-height: 1; */
  /* margin-bottom: var(--line-height-em); */
  /* margin-bottom: calc(var(--line-height-em)/2); */
}
