

.footer {
  background-color: rgba(253,251,246, 0.5);
  padding: calc(var(--line-height-em)*2) 1em;
  display: flex;
  align-items: flex-start;
}

.mit-logo {
  max-width: 100px;
  flex-grow: 0;
  margin-right: 2em;
}

.info {
  font-size: var(--fs-x-small);
}
