


.input {
  max-width: 90%;
  width: 50em;
  box-shadow: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid var(--mit-red);
  margin-bottom: var(--line-height-em);
}

.input:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none;

  border-bottom: 2px solid var(--mit-red--dimmed);
}


.subjects-filter {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: calc(var(--line-height-em)*2);
}

.subject-filter {
  margin-right: 2em;
  margin-bottom: calc(var(--line-height-em)/2);
  font-size: var(--fs-small);
  text-decoration: underline;
}

/*
.subjects-filter__prompt {
  font-size: var(--fs-small);
  color: var(--muted);
  font-weight: var(--fw-medium);
  margin-right: 2em;
}

.subject-filter {
  font-weight: var(--fw-medium);
  font-size: var(--fs-small);
  border-radius: 5px;
  margin-right: 1em;
  background: var(--mit-red);
  color: var(--white);
  padding: calc(var(--line-height-em)/4) calc(var(--line-height-em)/2);
  cursor: pointer;
}

.subject-filter:hover {
  background-color: var(--mit-red--dimmed);
} */
