

.topic__brief {
  margin-top: calc(var(--line-height-em)*2);
  margin-bottom: calc(var(--line-height-em)*2);
}


.pillar {
  margin-bottom: calc(var(--line-height-em)*2);
}

.pillar__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid var(--mit-light-gray);
  margin-bottom: calc(var(--line-height-em)/3*2);
  /* margin-top: calc(var(--line-height-em)/3*2); */
  padding-bottom: calc(var(--line-height-em)/3);
}


.pillar__list-item {
  display: flex;
  align-items: center;
  font-size: var(--fs-small);
  line-height: 1.3;
  list-style: none;
}

.pillar__list-item__icon {
  height: var(--line-height-em);
  margin-right: .5em;
}

.pillar__title {
  margin: 0;
}


.pillar__help {
  cursor: pointer;
  font-weight: var(--fw-medium);
  font-size: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  background-color: var(--mit-red);
  color: var(--white);
}

.pillar__help:hover {
  opacity: .8;
}

.no-assessments {
    color: var(--muted);
}

.canvas-wrapper {
  font-size: 2em;
  width: 100%;
  height: 400px;
}
