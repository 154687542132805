

.people {
  display: flex;
  /* justify-content: space-between; */
}

.person {
  width: 130px;
  max-width: 30%;
  margin-right: 5em;
  text-align: center;
}

.person__name {
  margin-top: calc(var(--line-height-em)/2);
  font-weight: var(--fw-medium);
  margin-bottom: 0;
}

.person__title {

}
