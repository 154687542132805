

.menu {
  padding: var(--line-height-em) 1em;
  width: 220px;
  background-color: rgba(253,251,246, 0.5);
}

.logo {
  margin-bottom: var(--line-height-em);
}

.menu__links {
  list-style: none;
}

.menu__links a {
  text-align: left;
}


.search__dropdown {
  font-size: var(--fs-small);
  background: var(--white);
  padding: var(--line-height-em) 1em;
}
