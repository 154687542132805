
.root {
}

.collapsible__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: var(--fs-small);
  color: var(--mit-red);
  margin-bottom: calc(var(--line-height-em)/2);
}


.collapsible__header__icon {
  height: 1em;
}


.collapsible__header:hover  {
  opacity: .7;
}

.collapsible__body {
  background: var(--block-gray);
  padding: var(--line-height-em);
  margin-bottom: var(--line-height-em);
}


.choices {
  list-style-type: upper-latin;
  margin-bottom: var(--line-height-em);
}


.show-hide-solution {
  font-size: var(--fs-small);
  color: var(--mit-red);
  cursor: pointer;
  text-decoration: underline;
}

.show-hide-solution:hover {
  opacity: .7;
}
